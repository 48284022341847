.recharts-responsive-container {

    .recharts-wrapper {
        padding: 12px;
        border-radius: 10px;
        margin: 20px;
        background: #fff;
        width: 97% !important;

    }

    margin-bottom: 50px;
}

.chart-container {
    position: relative;
}

.chart-title {
    position: absolute;

    /* Adjust this value as needed */
    left: 5%;
    font-size: 18px;
    color: rgba(73, 83, 106, .8);
    z-index: 1;

    @media (max-width: 850px) {
        margin-top: .5rem;
    }

}

.recharts-surface {
    margin-top: 40px;
}

.recharts-wrapper {
    height: 500px !important;
}

.chart-input {
    position: absolute;

    left: 69%;
    font-size: 18px;
    color: rgba(73, 83, 106, 0.8);
    z-index: 1;

    @media (max-width: 1334px) {
        left: 40%;
    }

    @media (max-width: 1188px) {
        left: 30%;
    }

    @media (max-width: 1014px) {
        left: 50%;
    }

    @media (max-width: 850px) {
        margin-top: .5rem;
    }


    .custom-input {
        width: 138px;
        height: 28px;
        border-radius: 5px;
        border: 1px solid #ccc;
        background: white;
    }



}

.chart-input-to {


    .custom-input {
        width: 138px;
        height: 28px;
        border-radius: 5px;
        border: 1px solid #ccc;
        background: white;
    }

    left: 85%;
    position: absolute;

    font-size: 18px;
    color: rgba(73, 83, 106, 0.8);
    z-index: 1;

    @media (max-width: 1334px) {
        left: 60%;
    }

    @media (max-width: 1188px) {
        left: 50%;
    }

    @media (max-width: 1097px) {
        left: 55%;
    }

    @media (max-width: 1014px) {
        left: 70%;
    }

    @media (max-width: 977px) {
        left: 76%;
    }

    @media (max-width: 850px) {
        margin-top: .5rem;
    }

    @media (max-width: 597px) {
        top: 60px;
        left: 50%;
    }
}

.chart-inputs {
    position: relative;
    top: 20px;

    @media (max-width: 850px) {
        display: flex;
        margin-top: .5rem;
    }

    .to {
        left: 83%;
        position: absolute;
        font-size: 18px;
        color: rgba(73, 83, 106, 0.8);
        z-index: 1;

        @media (max-width: 1334px) {
            left: 57%;
        }

        @media (max-width: 1188px) {
            left: 47%;
        }

        @media (max-width: 1097px) {
            left: 50%;
        }

        @media (max-width: 1014px) {
            display: none;
        }
    }

    .from {
        left: 66%;
        position: absolute;
        font-size: 18px;
        color: rgba(73, 83, 106, 0.8);
        z-index: 1;

        @media (max-width: 1646px) {
            left: 65%;
        }

        @media (max-width: 1334px) {
            left: 35%;
        }

        @media (max-width: 1014px) {
            display: none;
        }
    }


}