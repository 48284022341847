.pending-report-first {
    @media (max-width: 1199px) {
        margin-bottom: .5rem;
    }
}

.pending-report {
    @media (max-width: 490px) {
        flex-direction: column;
        align-items: center;

        .pending-form {
            margin-bottom: .5rem;
        }


        .react-datepicker__input-container {
            margin-bottom: .5rem;
        }
    }
}