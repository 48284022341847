.navbar-top {

    .container-fluid {
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: flex-end;
    }

    .navbar-search {
        .form {
            width: 50%;
        }

        @media (max-width: 930px) {
            .form {
                width: 70%;
            }
        }

        position: relative;
        width: 100%;
        align-self: flex-start;
        margin-top: 0.5rem;

        input,
        .form-input::placeholder {

            background-color: #F5F5F5;
            @extend .font-sidebar;
            font-size: 14px;
            color: rgb(73, 83, 106, .5);
            border: none;
        }

        .form-input {
            padding: 12px;
            padding-left: 50px;
        }

        .search-icon {
            position: absolute;
            top: 8px;
            padding-left: 1rem;
            padding-right: 2rem;
        }

    }
}