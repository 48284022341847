.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-card {
        border-radius: 10px;

        .col-lg-12 {
            margin-bottom: 20px;
        }

        .custom-margin-top {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .review-btn {
            width: 100%;
            padding: 12px;
            background: var(--btn-gradient);
        }

        .behavior-close-btn {
            margin-top: 12px;
            margin-right: 12px;
            display: flex;
            justify-content: flex-end;

            svg {
                height: 20px;
                fill: rgba(0, 0, 0, 0.5);
                cursor: pointer;
            }
        }
    }

    .form-control-alternative {
        width: 100%;
        border-radius: 5px;
        padding: 18px;
        @extend .font-sidebar;
        color: rgb(73, 83, 106, 0.5);
        background-color: #f5f5f5;
        border: none;
    }

    .form-control-alternative::placeholder {
        color: rgb(73, 83, 106, 0.5);

    }

    @media (max-width: 768px) {
        .modal-card {

            .custom-margin-top {
                margin-top: 20px;
                margin-bottom: 40px;
            }
        }
    }
}

.modal-dialog {
    .modal-content {
        border: none;
    }

    .modal-cross {
        position: relative;
        cursor: pointer;

        svg {
            position: absolute;
            top: -16px;
            right: 1px;
        }
    }

    .download {
        position: relative;
        cursor: pointer;

        svg {
            position: absolute;
            top: -16px;
            right: 30px;
            border-radius: 2px;
        }
    }

}

.dropdown-add {
    .form-input::placeholder {
        @extend .normal-font-sidebar;
        color: rgba(73, 83, 106, 0.5);
    }

    .margin-top {
        margin: 12px 0px;
    }

    .modal-card {
        width: 400px;

        // @media (max-width: 494px) {
        //     width: 350px;
        // }

        // @media (max-width: 406px) {
        //     width: 320px;
        // }

    }

    .add-modal-card {

        @media (max-width: 1075px) {
            height: 70%;
            overflow: scroll;
        }

    }

    .sftp-card {
        padding: 12px;
    }

    .close-btn {
        svg {
            height: 20px;
            fill: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
    }
}

.image-container {
    padding: 12px;
    background-color: rgba(243, 243, 243, 1);
    border-radius: 12px;
}