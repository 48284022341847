.main-content {
    position: relative;

    // Navbar
    .navbar-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        background-color: var(--color-white-light-2);
        padding-left: 0 !important;
        padding-right: 0 !important;
        box-shadow: var(--shadow-dark);

        @media (max-width: $bp-tab-phone) {
            display: none;
        }
    }



}


// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {

    @each $breakpoint,
    $dimension in $grid-breakpoints {

        &-#{$breakpoint} {

            @include media-breakpoint-up(#{$breakpoint}) {

                // Left
                &.fixed-left+.main-content {
                    margin-left: 260px;
                }

                // Right
                &.fixed-right+.main-content {
                    margin-right: 260px;
                }
            }
        }
    }
}

.container-fluid {
    padding-left: 20px;
    padding-right: 20px
}