.input-group {


    .form-control {
        box-shadow: none;
        border: 1px solid rgba(163, 163, 163, 0.4);
        background-color: rgba(163, 163, 163, 0.03);

        &:not(:first-child) {
            border-left: 0;
            padding-left: 0;
        }

        &:not(:last-child) {
            border-right: 0;
            padding-right: 0;
        }

        &:focus {
            box-shadow: none;

        }



    }
}

.input-group-text {
    transition: $input-transition;
}

.form-logo {
    display: flex;
    align-items: center;
    padding: 0.625rem 0.75rem;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    background-color: rgba(163, 163, 163, 0.03);
    border: 1px solid rgba(163, 163, 163, 0.4);
    border-radius: 0.375rem;


}

.border-start-0 {
    cursor: pointer;
}

.form-input {
    padding: 16px;
}


.input-group-alternative {
    transition: box-shadow .15s ease;

    .form-control,
    .input-group-text {
        box-shadow: none;
    }
}




.pending-dropdown {
    color: rgba(73, 83, 106, .7);
    background-color: rgba(73, 83, 106, .05);
    cursor: pointer;

}

.date-picker {
    cursor: pointer;
}

input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}