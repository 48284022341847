.settings {
    border-radius: 5px;
    background: #fff;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 13px 0px;
    margin-bottom: 1.5rem;

    .card-body {
        padding: 24px;
        cursor: pointer;
    }

    .arrow-icon-down {
        transition: transform .5s ease-out;
    }

    .arrow-icon-up {
        transform: rotate(180deg);
        transition: transform .5s ease-out;

    }

    @media only screen and (min-width: 767px) and (max-width: 1000px) {
        &:first-child {
            margin-top: 120px;
        }

        &:last-child {
            margin-bottom: 120px;
        }
    }

    @media (max-width: 768px) {
        &:first-child {
            margin-top: 90px;
        }

        &:last-child {
            margin-bottom: 90px;
        }
    }

    .settings-card {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .settings-card-title {
            @extend %base-font-styles;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 144.523%;
            letter-spacing: 0.33px;

            @media (max-width: $bp-tab-port) {
                font-size: 18px;
            }
        }

        @media (max-width: $bp-tab-port) {
            svg {
                width: 25px;
            }
        }


    }

    .expanded-row {
        border-top: 2px solid rgba(73, 83, 106, 0.2);
        padding: 24px;
        max-height: 200px;
        overflow: auto;

        .add-title {
            @extend %base-font-styles;
            opacity: 0.9;
            background: var(--primary-gradient-2);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border: none;
            outline: none;
            font-size: 14px;


        }

        .add-btn {
            display: inline-flex;
            padding: 8px 18px;
            border-radius: 5px;
            border: 1px solid rgba(73, 83, 106, 0.20);
            margin: 10px;
            top: -3px;


            @media (max-width: 575px) {
                input {
                    margin: 0
                }
            }

        }

        .addded-title {
            @extend %base-font-styles;
            padding: 1px;
        }
        .plus-add{
            cursor: pointer;
        }

        .added-designation {
            position: relative;

            .remove-icon {
                position: absolute;
                top: 0;
                right: -1px;
                cursor: pointer;
            }
        }
    }

}