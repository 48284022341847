.btn-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #ffffff47;
    border-radius: 8px 0 8px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  button {
    border: none;
    color: #737373;
    background-color: #ffffff47;
    padding: 10px;
    cursor: pointer;
  }
  
  