// Vertical navbar
.navbar-vertical {
    box-shadow: var(--shadow-dark);
    @extend .font-sidebar;

    &.navbar {
        border-right: 2px solid var(--color-white-light-1, #F5F5F5);
        background: var(--color-white-light-2);
        color: var(--color-secondary-text);
        padding: 1rem;

        @media (max-width: $bp-tab-phone) {
            padding: 0;
        }
    }

    .navbar-nav .nav-link.active {
        background-color: var(--color-primary-light-opacity);
        color: var(--color-secondary-text);
    }

    .navbar-nav {
        flex-direction: column;

    }

    .navbar-toggler {
        border: none;
    }


    &.navbar-expand-md {

        @media (min-width: $bp-tab-phone) {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            width: 100%;
            max-width: 260px;
            overflow-x: auto;
        }
    }




    @media (max-width: 500px) {
        .m-expense_logo {
            svg {
                width: 130px;
            }
        }

        .noti {
            svg {
                width: 20px;
            }

        }
    }




    @media (min-width: $bp-tab-phone) {
        .container-fluid {
            flex-direction: column;
            align-items: stretch;
            min-height: 100%;
            padding-left: 0;
            padding-right: 0;
        }

        .navbar-collapse {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;

        }

        .divider {
            display: none;
        }

        .m-expense_logo {
            margin-bottom: 45px;
        }
    }

}