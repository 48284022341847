.dropdown-menu-arrow {
    border-radius: 10px;
    background: #FCFCFC;
    box-shadow: 0px 0px 25px 0px rgba(131, 131, 131, 0.30);
    inset: auto;

    .noti-title,
    .dropdown-item {
        @extend .font-sidebar;
        font-size: 13px;

        .media {
            display: flex;
        }

        padding-top: 0;
        padding-bottom: 0;
    }

    .dropdown-item {
        display: flex;
        align-items: center;

        .dropdown-svg {
            margin-right: 7px;

        }
    }

    .avatar {
        margin-right: 7px;
    }
}