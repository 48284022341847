.m-expense-table-container {

  background-color: #fff;
  border-radius: 6px 6px 0px 0px;
  max-height: 650px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 13px 0px;
  padding: 0.5rem;


  .ag-header-viewport {
    background-color: rgba(13, 167, 224, 1);

    .ag-icon {
      color: #fff;
    }
  }

  .ag-header-cell-text {
    @extend .dashboard-card-title-font;

  }

  .ag-paging-description {
    @extend .normal-font-sidebar;
    color: rgba(73, 83, 106, 0.9);

  }

  .ag-button {
    .ag-icon {
      color: rgba(73, 83, 106, .8);
    }
  }

  .ag-icon-small-down::after,
  .ag-icon-small-down {
    color: rgba(73, 83, 106, .8);

  }



  .ag-cell-value,
  .ag-paging-row-summary-panel,
  .ag-picker-field-display {
    @extend .normal-font-sidebar;
    color: rgba(73, 83, 106, 0.7);

  }

  .ag-picker-field {
    .ag-label {
      @extend .normal-font-sidebar;
      color: rgba(73, 83, 106, 0.9);


    }
  }

  .ag-ltr .ag-cell {
    border-right: 2px solid rgba(73, 83, 106, .06);
    display: flex;
    align-items: center;

  }


  .ag-row {
    border: none;
  }

  .ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
    height: 100%;
    top: 0;
    background-color: rgba(73, 83, 106, .06);
  }



  .ag-header-cell-menu-button {
    opacity: 1;
  }

  .ag-header-cell-menu-button:not(.ag-header-menu-always-show) {
    opacity: 1;
    margin-left: .5rem
  }

}