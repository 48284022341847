@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat&display=swap');

:root {
  --color-primary: rgba(50, 188, 232, 1);
  --color-primary-light: rgba(13, 167, 224, 1);
  --color-primary-light-opacity: rgba(13, 167, 224, 0.05);
  --color-primary-dark: rgba(215, 245, 255, 1);

  --color-white-light-1: rgba(245, 245, 245, 1);
  --color-white-light-2: rgba(254, 254, 254, 1);
  --color-white-light-3: rgba(11, 11, 11, 0.55);
  --color-white-light-4: rgba(253, 253, 253, 1);
  --color-white-light-5: rgba(255, 255, 255, 1);

  --color-grey-dark-1: rgba(255, 255, 255, 1);
  --color-secondary-text: rgba(73, 83, 106, 0.7);

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.05);
  --white: rgba(252, 252, 252, 1);

  --primary-gradient: linear-gradient(184deg, #85C2FA -50.13%, #25B2EB 93.26%);
  --primary-gradient-2: linear-gradient(180deg, #85C2FA 0%, #25B2EB 100%);
  --btn-gradient: linear-gradient(180deg, rgb(133, 194, 250) 0%, rgb(37, 178, 275) 100%);

  --toastify-color-progress-success: var(--color-primary-light);
  --toastify-icon-color-success: var(--color-primary-light);
  --ag-icon-font-code-menu: url("data:image/svg+xml;charset=utf-8,<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M16 16L12.3809 12.3809M12.3809 12.3809C12.9999 11.7618 13.491 11.0269 13.826 10.218C14.1611 9.40917 14.3335 8.54225 14.3335 7.66676C14.3335 6.79127 14.1611 5.92435 13.826 5.1155C13.491 4.30665 12.9999 3.57172 12.3809 2.95265C11.7618 2.33358 11.0269 1.84251 10.218 1.50748C9.40917 1.17244 8.54225 1 7.66676 1C6.79127 1 5.92435 1.17244 5.1155 1.50748C4.30665 1.84251 3.57172 2.33358 2.95265 2.95265C1.70239 4.20291 1 5.89863 1 7.66676C1 9.4349 1.70239 11.1306 2.95265 12.3809C4.20291 13.6311 5.89863 14.3335 7.66676 14.3335C9.4349 14.3335 11.1306 13.6311 12.3809 12.3809Z' stroke='%23FCFCFC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
}


$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px);

$bp-tab-land: 68.75em; //1100
$bp-tab-port: 55.25em; //884
$bp-tab-phone: 48em; //768
$bp-mini-phone: 40em; //640
$bp-mini: 36.12em; //578
$bp-mini-small: 30.45em; //487
$mini: 27.68em; //443
$small-1: 21.44em; //343 
$small: 20.38em; //326

%base-font-styles {
  color: var(--color-secondary-text);
  font-family: 'Inter', sans-serif;
}

// %custom-check-box {

//   .custom-checkbox,
//   .m-expense-checkbox,
//   .ag-checkbox-input {
//     width: 16px;
//     height: 16px;
//     margin: 0;
//     padding: 0;
//     opacity: 1;
//     appearance: none;
//     border-radius: 3px;
//     background: transparent;
//     position: relative;
//     margin-right: 10px;
//     border: 2px solid rgba(73, 83, 106, 0.2);

//     &:checked:before,
//     &:checked:after,
//     .ag-checkbox-input-wrapper.ag-checked::after {
//       content: "";
//       position: absolute;
//       height: 2px;
//       background: #fff;
//     }

//     &:checked,
//     .m-expense-checkbox:checked,
//     .ag-checkbox-input-wrapper.ag-checked::after {
//       border: 2px solid var(--primary-gradient);
//       background: var(--primary-gradient);
//       background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><rect width="16" height="16" rx="2" fill="url(%23paint0_linear_5_908)"/><path d="M4 8L6.66667 10.6667L12 5.33334" stroke="%23FCFCFC" stroke-width="1.5" stroke-linecap="round"/><defs><linearGradient id="paint0_linear_5_908" x1="-6.69388" y1="-10.24" x2="-8.46144" y2="14.2333" gradientUnits="userSpaceOnUse"><stop stop-color="%2385C2FA"/><stop offset="1" stop-color="%2325B2EB"/></linearGradient></defs></svg>') no-repeat center center;
//     }

//     &:focus {
//       outline: none;
//     }


//   }

// }

.font-sidebar {
  @extend %base-font-styles;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 148.858%;
  letter-spacing: -0.18px;
}

.normal-font-sidebar {
  @extend %base-font-styles;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.dashboard-card-title-font {
  color: var(--white);
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
}

.dashboard-card-number-font {
  color: var(--white);
  font-family: 'Inter';
  font-size: 40px;
  font-weight: 600;
}

.form-title {
  color: rgba(73, 83, 106, 0.8);
  font-family: 'Inter';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  background-color: var(--color-white-light-1);

}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.header {
  @media (max-width: 768px) {
    display: none;
  }
}

.custom-input {
  display: flex;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(73, 83, 106, 0.05);
}

.date-value {
  color: rgba(73, 83, 106, .7);
  margin-right: 1rem;
}

.react-datepicker-wrapper {
  margin-right: 1rem;
}

.react-datepicker__input-container {
  @media (max-width:431px) {


    margin-bottom: .5rem;

  }
}