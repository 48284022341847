.pending-list {
  // @extend %custom-check-box;

  // .custom-checkbox,
  // .m-expense-checkbox {
  //   @media (max-width: 1008px) {
  //     height: 14px;
  //     width: 14px;

  //   }

  //   @media only screen and (min-width: 765px) and (max-width: 865px) {
  //     width: 20px;
  //   }

  //   @media (max-width: 500px) {
  //     width: 20px;
  //   }
  // }

  .pending-list-image {
    opacity: 0.95;
    background: var(--primary-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;

    &:hover {
      opacity: 1;
      background: var(--color-secondary-text);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .select-title {
    @extend .font-sidebar;
  }

  .m-expense-checkbox {
    border: 2px solid var(--white);
  }



}