.otp-section {
    @media (max-width: 500px) {
        padding: 20px;
    }

    .otp-row {
        margin: auto;
        /* center horizontally */

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 100%;

        input {
            margin: 0.5rem;
            padding: 0.5rem;
            max-width: 5rem;
            height: 6rem;
            width: 10rem;

            @media (max-width: 500px) {
                height: 5rem;
                width: 4rem;
            }

            @media (max-width: 400px) {
                height: 4rem;
                width: 3rem;
            }

            text-align: center;
            background-color: rgba(245, 245, 245, 1);
            border: none;
            @extend .normal-font-sidebar;
            font-size: 18px;
            color: rgba(73, 83, 106, 0.8);
            border-radius: 5px;

            &:focus {
                outline: none;
                border: 2px solid rgb(133, 194, 250, .5);
            }

        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}