.btn {
  border-radius: 5px;
  border: none;
  background: var(--color-primary-light);
  @extend .dashboard-card-title-font;

  @media (max-width: $bp-tab-phone) {
    font-size: 14px;
  }

  @media (max-width: $bp-mini-phone) {
    font-size: 12px;
  }

  &:focus,
  &:active,
  &.active,
  .btn-check:focus+&,
  .btn-check:checked+&,
  .btn-check:active+& {
    outline: 0;


    &:focus {
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      transform: translateY(-1px);
      background-color: rgb(36 165 213);
      color: var(--white);
    }
  }

  &:hover,
  &:focus {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
    background-color: rgb(36 165 213);
    color: var(--white);
  }
}