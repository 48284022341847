.review-card {

  border-radius: 10px;
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 13px 0px;
  border: none;

  select {
    width: 100%;
    border: 1px solid rgba(73, 83, 106, .2);
    border-radius: 5px;
    color: rgba(73, 83, 106, .8);
    padding: 5px;
  }

  .option-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: rgba(73, 83, 106, .6);
    margin-bottom: 8px;

  }

  .review-title {
    @extend .font-sidebar;

    @media (max-width: 575px) {
      font-size: 14px;
    }
  }

  .review-btn-div {

    @media (min-width: 578px) {
      justify-content: flex-end;
    }

    @media (max-width: 578px) {
      justify-content: center;
    }
  }

  .review-title-div {
    @media (max-width: 578px) {
      margin-bottom: 8px;
    }
  }
}

.select-card {
  padding: 12px;
}

.review {
  padding-top: 23px;
  padding-bottom: 23px;
}

.review-dashboard {
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
}