.archived-report {
    justify-content: flex-end;

    @media (max-width: 992px) {
        justify-content: flex-start;
    }

    @media (max-width:431px) {
        flex-direction: column;
        align-items: center;

        .date-picker {
            margin-bottom: .5rem;
        }
    }

}