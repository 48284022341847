@import "react-toastify/dist/ReactToastify.css";

@import 'bootstrap/scss/bootstrap';
@import "./base";
@import "./layouts/Button/btn";


@import "./layouts/sidebar/sidebar_vertical";
@import "./layouts/sidebar/sidebar-collapse";
@import "./layouts/sidebar/sidebar-dropdown";
@import "./layouts/sidebar/sidebar";


@import "./layouts/content/content";
@import "./layouts/content/header";

@import "./components/Table/table";

@import "./layouts/navbars/logo";
@import "./layouts/navbars/dropdown";
@import "./layouts/navbars/navbar";

@import "./components/Archived/archived";

@import "./components/Dashboard/dashboard";
@import "./components/Dashboard/review";
@import "./components/Dashboard/chart";

@import "./components/Employee/employee";
@import "./components/Employee/excel";
@import "./components/Employee/list";

@import "./components/Pending/pending";
@import "./components/Pending/list";

@import "./components/LogIn/login";

@import "./components/Input/input";


@import "./components/Settings/settings";
@import "./layouts/DropDown/dropdown";

@import "./components/Password/change_password";
@import "./components/Password/password_reset";
@import "./components/Password/Verify_otp";



@import "./layouts/Modal/modal";
@import "./layouts/Loader/loader";