.password-reset {

    background-color: rgba(245, 245, 245, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89vh;



    .forgot-title {
        @extend .normal-font-sidebar;
    }

    .reset {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 20px;
        padding: 50px 35px;

        @media (max-width: 500px) {
            padding: 30px;
        }

        @media (max-width: 400px) {
            padding: 20px;
        }
    }

    .back {
        color: rgba(73, 83, 106, .6);
        border: none;
        background-color: transparent;
        font: 600 16px/22px Inter;
        letter-spacing: 0;
        text-decoration: none;
    }

    .back:hover {
        background-color: rgba(73, 83, 106, .05);
    }

    .form-input::placeholder {
        @extend .normal-font-sidebar;
        color: rgba(73, 83, 106, 0.7);
    }

    .form-input {
        width: 350px;
        border: none;
        background-color: rgba(245, 245, 245, .8);

        @media (max-width: 500px) {
            width: 300px;
        }

        @media (max-width: 400px) {
            width: 255px;
        }

    }

    .form-big-title {
        font-size: 28px;

        @media (max-width: 500px) {
            font-size: 26px;
        }
    }

    .forgot-title {
        margin-bottom: 1.7rem;
    }

    .input-group {
        margin-bottom: 1.5rem;
    }



}



.password-navbar {
    background-color: white;
    padding: 15px;

    .login {
        @extend .normal-font-sidebar;
        text-decoration: none;
    }

    @media (max-width: 500px) {
        .m-expense_logo svg {
            width: 150px;
        }
    }
}