.employee-table {
    @media (max-width: 1081px) {
        .employee-svg {
            padding-left: 0;
            padding-right: 0;
        }


    }

}

.add-employee {
    justify-content: flex-end;
}

.add-title {
    @media (max-width: 575px) {
        margin-bottom: 12px;
    }
}

.add-employee-title {
    @media (max-width: 575px) {
        margin-bottom: .5rem;
    }
}

.first-name-input {
    @media (max-width: 992px) {
        padding-right: 12px;
    }
}