.card-container {


  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }

  .dashboard-card {
    border: none;
    border-radius: 10px;
    background: var(--primary-gradient);
    height: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;

    .card-body {
      padding: 30px;

      @media (max-width: 1000px) {
        padding: 20px;
      }

      svg {
        @media (max-width: 1055px) {
          width: 50px;
        }

        @media (max-width: 905px) {
          width: 30px;
        }

        @media (max-width: 767px) {
          width: 70px;
        }
      }


    }

    .dashboard-card-section {
      margin-left: 26px;

      .dashboard-card-number {
        @extend .dashboard-card-number-font;

        @media (max-width: 1000px) {
          font-size: 30px;
        }
      }

      .dashboard-card-title {
        @extend .dashboard-card-title-font;
        margin-top: 6px;


      }
    }

  }

}