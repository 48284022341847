.login-container {
    height: 100vh;

    .form-blue-logo {
        display: none;

        @media (max-width: $bp-tab-phone) {
            display: flex;
            justify-content: center;
            margin-bottom: 12px;
        }
    }

    p {
        margin: 0;
    }

    display: flex;

    .login-form {
        width: 40%;
        background-color: var(--color-white-light-2);
        // height: 100vh;

        @media (max-width: $bp-tab-phone) {
            width: 100%;
            justify-content: center;
        }
    }


    .login-background {
        position: relative;
        overflow: hidden;
        width: 60%;
        background: var(--primary-gradient-2);
        // height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $bp-tab-phone) {
            display: none;
        }

        .ellipse-1,
        .ellipse-2,
        .ellipse-3 {
            position: absolute;
            overflow: hidden;
            background-color: transparent;
            height: 1150px;
            width: 150%;
            border-radius: 50%;
            border: 1px solid rgba(217, 217, 217, 0.6);
        }

        .ellipse-1 {
            left: 25%;
            bottom: -1000px;
        }

        .ellipse-2 {
            bottom: -1000px;
            width: 130%;
        }

        .ellipse-3 {
            right: 10%;
            bottom: -1000px;
            width: 150%;
        }

        .svgo {
            position: absolute;
        }
    }


    .form-title {
        .form-big-title {
            @extend .form-title
        }

        .form-small-title {
            @extend .normal-font-sidebar;
        }

        .form-big-title,
        .form-small-title {
            @media (max-width: $bp-tab-phone) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .form-wrap {
        padding: 140px;

        @media (max-width: 1446px) {
            padding: 80px;
        }

        @media (max-width: 1097px) {
            padding: 50px;
        }

        @media (max-width: 926px) {
            padding: 20px;
        }

        @media (max-width: $bp-tab-phone) {
            width: 50%;
        }

        @media (max-width: 630px) {
            width: 70%;
            padding: 0;
        }

        @media (max-width: 440px) {
            width: 90%;
            padding: 0;
        }
    }

    .form-group-btn {
        width: 100%;

        .btn {
            width: 100%;
            padding: 12px;
        }
    }

    .forgot-title {
        @extend .normal-font-sidebar;
        text-decoration: none;
    }

    .remember-title,
    .form-input::placeholder {
        @extend .normal-font-sidebar;
        color: rgba(73, 83, 106, 0.5);
    }

    .form-title {
        margin-bottom: 35px;

    }

    // @extend %custom-check-box;






}